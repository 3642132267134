.terapias {
    position: relative;
    z-index: 2;
    display: block;
    width: 100%;
    height: auto;
    
    article {
        position: relative;
        z-index: 3;
        background-color: #F0EBE8;
        padding: 100px 0;
        h1 {
            color: $roxo;
            small {
                color: $azul;
                font-size: 1.125rem;
                line-height: 1.375rem;
                font-weight: 600;
            }
        }
    
        .texto {
            color: $roxo;
        }
    
        .topicos {
            a {
                .topico {
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 203px;
                    height: 203px;
                    border-radius: 50%;
                    margin-bottom: 40px;
                    @include breakpoint(768px, max) {
                        width: 145px;
                        height: 145px;
                    }
                    &::before {
                        position: absolute;
                        z-index: 1;
                        content: '';
                        width: 222px;
                        height: 222px;
                        border: $azul 3px solid;
                        border-radius: 50%;
                        border-width: 0 111px 0 0;
                        right: -10px;
                        top: -10px;
                        transition: all .3s ease;
                        @include breakpoint(768px, max) {
                            width: 165px;
                            height: 165px;
                            border-width: 0 80px 0 0;
                        }
                    }
                    &::after {
                        position: absolute;
                        z-index: 1;
                        content: '';
                        width: 222px;
                        height: 222px;
                        border: $azul 3px solid;
                        border-radius: 50%;
                        left: -10px;
                        top: -10px;
                        @include breakpoint(768px, max) {
                            width: 165px;
                            height: 165px;
                        }
                    }
                    h2 {
                        position: relative;
                        z-index: 3;
                        width: 203px;
                        height: 203px;
                        background-color: $roxo;
                        border-radius: 50%;
                        font-size: 1.1rem;
                        line-height: 1.75rem;
                        font-weight: 300;
                        text-align: center;
                        color: #ffffff;
                        padding: 75px 20px;
                        margin-bottom: 0;
                        @include breakpoint(768px, max) {
                            padding: 54px 20px;
                            width: 145px;
                            height: 145px;
                            font-size: 1rem;
                            line-height: normal;
                        }
                    }
                    &:hover {
                        &::before {
                            border-width: 0 223px 0 0;
                            @include breakpoint(768px, max) {
                                border-width: 0 165px 0 0;
                            }
                        }
                    }
                }
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .containerWaveHeader {
        position: relative;
        .wave1 {
            position: absolute;
            display: block;
            width: 100%;
            margin-top: -10px;
            z-index: 1;
        }
        .wave2 {
            position: relative;
            z-index: 2;
        }
    }
    .containerWaveFooter {
    
        margin-top: -80px;
    }


}