.wp-pagenavi {

	.pages {
		-webkit-border-radius: 7px;
		border-radius: 7px;
		border: #dadada 1px solid;
		background-color: #FFF;
		padding: 7px 15px;
		margin-right: 3px;
	}

	.current {
		-webkit-border-radius: 7px;
		border-radius: 7px;
		padding: 7px 15px;
		margin-right: 3px;
		color: $azulF;
		background-color: #FFFFFF;
		border: #dadada 1px solid !important;
		text-transform: uppercase;
	}

	.extend {
		-webkit-border-radius: 7px;
		border-radius: 7px;
		padding: 7px 15px;
		margin-right: 3px;
		background-color: #FFF;
		border: #dadada 1px solid;
		text-transform: uppercase;
	}

	a {

		-webkit-border-radius: 7px;
		border-radius: 7px;
		padding: 7px 15px;
		margin-right: 3px;
		color: #473f24;
		background-color: #FFF;
		border: #dadada 1px solid;
		text-transform: uppercase;

		&:hover {
			color: #fff;
			background-color: #473f24;
			border-color: #dadada;
			text-decoration: none;
		}
	}
}