.form-group {
    .form-control {
        border: $roxo 1px solid;
        height: auto;
        font-size: 0.875rem;
        background-color: transparent;
        outline: none;
        font-weight: 300;
        font-size: 1.125rem;
        color: $roxo;
        text-align: left;
        padding: 0.7rem 0.8125rem;
        border-radius: 0;
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: $roxo;
        }
        &::-moz-placeholder { /* Firefox 19+ */
            color: $roxo;
        }
        &:-ms-input-placeholder { /* IE 10+ */
            color: $roxo;
        }
        &:-moz-placeholder { /* Firefox 18- */
            color: $roxo;
        }
        &:focus {
            outline: none;
        }
    }
    .erroEmail {
        font-size: 0.625rem;
        color: $error;
        margin-top: 2px;
        display: none;
    }
    &.errorField {
        .form-control {
            border-color: $error;
            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: #FF7F7F;
            }
            &::-moz-placeholder { /* Firefox 19+ */
                color: #FF7F7F;
            }
            &:-ms-input-placeholder { /* IE 10+ */
                color: #FF7F7F;
            }
            &:-moz-placeholder { /* Firefox 18- */
                color: #FF7F7F;
            }
        }
    }
}