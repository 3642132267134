.modalWhatsApp {
    position: fixed;
    z-index: 33;
    right: 0;
    bottom: 40px;
    transform: translateY(-50%);
    width: auto;
    height: auto;
    background-color: green;
    display: block;
    padding: 0 12px;
    border-radius: 30px 0 0 30px;
    @include breakpoint(768px, max){
        top: auto;
        transform: none;
        bottom: 20px;
    }
    i {
        color: #ffffff;
        text-align: center;
        font-size: 20px;
        padding: 12px;
    }
    span {
        display: inline-block;
        vertical-align: middle;
        color: #fff;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: bold;
        transform: translateY(-2px);
    }
}