.contato {
    position: relative;
    z-index: 2;
    display: block;
    width: 100%;
    height: auto;
    
    article {
        position: relative;
        background-color: #F0EBE8;
        z-index: 3;
        padding: 80px 0 0;
        @include breakpoint(768px, max) {
            padding-bottom: 50px;
        }
        h1 {
            color: $roxo;
            text-align: center;
            margin-bottom: 60px;
            small {
                color: $azul;
                font-size: 1.125rem;
                line-height: 1.375rem;
                font-weight: 600;
            }
        }

        .mapa {
            iframe {
                display: block;
                width: 100%;
                height: 400px;
            }
        }

        .unidades {
            .unidade {
                margin-bottom: 30px;

                .tagFacil {
                    position: absolute;
                    background-color: $roxo;
                    font-size: 0.625rem;
                    line-height: 0.8125rem;
                    text-transform: uppercase;
                    padding: 8px 12px;
                    text-align: center;
                    color: #ffffff;
                    transform: rotate(-9deg);
                    left: 70%;
                    top: -5px;
                    @include breakpoint(768px, max) {
                        left: auto;
                        right: 0;
                        top: -35px;
                    }
                }

                h2 {
                    text-transform: uppercase;
                    color: $roxo;
                    font-weight: 800;
                    font-size: 1.125rem;
                    line-height: 1rem;
                }
                .dados {
                    .telefones {
                        font-size: 1.125rem;
                        line-height: 300;
                        color: $roxo;
                        margin-bottom: 5px;
                            line-height: 22px;
                            i {
                                margin-left: 4px;
                                margin-right: 8px;
                                font-size: 1.25rem;
                                &.ico-what {
                                    margin-right: 0;
                                }
                            }
                            span {
                                @include breakpoint(768px, max) {
                                    display: block;
                                    margin-top: 4px;
                                }
                            }
                    }
                    .infos {
                        margin-bottom: 5px;
                        .icone {
                            display: inline-block;
                            width: 100%;
                            max-width: 20px;
                            color: $roxo;
                            font-size: 1.25rem;
                            text-align: center;
                            margin-right: 8px;
                            @include breakpoint(768px, max) {
                                vertical-align: top;
                            }
                        }
                        .info {
                            display: inline-block;
                            font-size: 1.125rem;
                            line-height: 300;
                            color: $roxo;
                            line-height: 22px;
                            @include breakpoint(768px, max) {
                                width: 100%;
                                max-width: calc(100% - 40px);
                            }
                        }
                    }
                }
            }
        }

        form {
            @include breakpoint(768px, max) {
                margin-bottom: 70px;
            }
        }

        .redes {
            nav {
                ul {
                    li {
                        display: inline-block;
                        margin-right: 10px;
                        a {
                            display: block;
                            color: $roxo;
                            font-size: 2.125rem;
                            transition: all .3s ease;
                            &:hover {
                                text-decoration: none;
                                transform: scale(1.1);
                            }
                        }
                    }
                }
            }
        }

    }
}