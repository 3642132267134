.vitrine {
    position: relative;
	display: block;
	width: 100vw;
    height: auto;
    z-index: 2;

    .sliderVitrine {
        height: 100vh;
        max-height: 755px;
        div {
            display: block;
            overflow: hidden;
            .slider {
                display: flex;
                align-items: center;
                height: 100vh;
                max-height: 755px;
                background-repeat: no-repeat;
                background-position: center center;
                @include breakpoint(tablet){
                    align-items: initial;
                }
                picture {
                    position: absolute;
                    display: block;
                    img {
                        display: block;
                        width: 100%;
                        height: 100vh;
                        max-height: 755px;
                        object-fit: cover;
                    }
                }
                h1 {
                    color: #ffffff;
                    @include breakpoint(tablet){
                        margin-top: 115px;
                        font-size: 1.75rem;
                        line-height: 2rem;
                        margin-bottom: 20px;
                    }
                }
                .texto {
                    color: #ffffff;
                    font-weight: 300;
                    margin-bottom: 30px;
                    .linha1 {
                        font-style: italic;
                        font-size: 1.25rem;
                        line-height: 1.5rem;
                        margin-bottom: 8px;
                        @include breakpoint(768px, max) {
                            font-size: 1rem;
                            margin-bottom: 0;
                        }
                    }
                    .linha2 {
                        font-style: italic;
                        font-size: 2rem;
                        line-height: 2.4rem;
                        @include breakpoint(768px, max) {
                            font-size: 1.5rem;
                            line-height: normal;
                        }
                    }
                }
                a {
                    &.btn {
                        @include breakpoint(768px, max) {
                            display: table;
                            font-size: 12px;
                        }
                        i {
                            @include breakpoint(tablet) {
                                font-size: 18px;
                            }
                        }
                    }
                }
                ul {
                    li {
                        list-style-type: none;
                        color: #fff;
                        margin-bottom: 6px;
                        i {
                            color: $azul;
                        }
                    }
                }
            }
        }
    }

    .tns-controls {
        width: 100%;
        max-width: 1170px;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        bottom: 60px;
        z-index: 2;
        @include breakpoint(768px, max) {
            max-width: calc(100% - 40px);
        }
        button {
            border: 0;
            text-indent: 1000%;
            white-space: nowrap;
            overflow: hidden;
            background-color: transparent;
            width: 50px;
            height: 50px;
            background-repeat: no-repeat;
            background-position: center center;
            &:first-child {
                background-image: url($img + 'vitrine/left.svg');
            }
            &:last-child {
                background-image: url($img + 'vitrine/right.svg');
            }
        }
    }
    
}