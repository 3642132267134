.sexualidade {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    height: auto;
    // padding: 280px 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    // margin-top: -250px;
    // margin-bottom: -245px;
    &::before {
        position: absolute;
        content: '';
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($roxo, 0.7);
    }

    .waveContainer  {
        position: relative;
        z-index: 2;
    }

    article {
        position: relative;
        z-index: 3;
        display: block;
        width: 100%;
        padding: 80px 0;
        h1 {
            color: #ffffff;
            small {
                color: $azul;
                font-size: 1.125rem;
                line-height: 1.375rem;
                font-weight: 600;
            }
        }
    
        .texto {
            color: #ffffff;
        }
    
        .card {
            border: 0;
            margin-bottom: 2px;
            background-color: rgba($roxo, 0.6);
            .card-header {
                padding: 0;
                border-bottom: 0;
                h5 {
                    .btn {
                        display: block;
                        width: 100%;
                        text-align: left;
                        text-transform: none;
                        color: #ffffff;
                        font-weight: 400;
                        &:hover {
                            background-color: rgba($roxo, 0.7);
                        }
                    }
                }
            }
            .card-body {
                p {
                    color: #ffffff;
                    font-size: 14px;
                    line-height: 28px;
                }
            }
        }
    }

}