.blog {
    position: relative;
    z-index: 2;
    display: block;
    width: 100%;
    height: auto;
    @include breakpoint(768px, max) {
        margin-bottom: 50px;
    }
    
    article {
        position: relative;
        z-index: 3;
        h1 {
            color: $roxo;
            text-align: center;
            margin-bottom: 50px;
            small {
                color: $azul;
                font-size: 1.125rem;
                line-height: 1.375rem;
                font-weight: 600;
            }
        }

        a {
            display: block;
            width: 100%;
            height: auto;
            margin-bottom: 40px;
            figure {
                position: relative;
                overflow: hidden;
                img {
                    transition: all .3s ease;
                }
            }
            h2 {
                font-size: 1.375rem;
                line-height: 1.625rem;
                color: $roxo;
            }
            .texto {
                color: #4d4d4d;
                font-size: 1rem;
                line-height: 1.625rem;
            }
            &:hover {
                text-decoration: none;
                figure {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
            &.btn-azul {
                display: table;
                width: auto;
                margin: auto;
            }
        }

    }

    &.singleBlog {
        .container {
            max-width: 900px;
        }
    }
}