.sobre {
    display: block;
    width: 100%;
    height: auto;
    // margin-bottom: 80px;
    padding: 80px 0;
    @include breakpoint(tablet) {
        padding: 40px 0;
    }

    h1 {
        color: $roxo;
        text-transform: none;
        small {
            color: $azul;
            font-size: 1.125rem;
            line-height: 1.375rem;
            font-weight: 600;
        }
    }

}