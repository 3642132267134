.curso {
    position: relative;
    z-index: 2;
    display: block;
    width: 100%;
    height: auto;
    @include breakpoint(768px, max) {
        margin-bottom: 50px;
    }
    
    article {
        position: relative;
        z-index: 3;
        background-color: $roxo;
        padding: 80px 0;
        h1 {
            color: $azul;
            padding-left: 40px;
            @include breakpoint(768px, max) {
                padding-left: 0;
            }
        }
        .texto {
            color: #ffffff;
            padding-left: 40px;
            @include breakpoint(768px, max) {
                padding-left: 0;
            }
        }
        a {
            &.btn-azul {
                margin-left: 40px;
                @include breakpoint(768px, max) {
                    margin-left: 0;
                }
            }
        }
    }
}