footer {
    background-color: $roxo;
    padding-bottom: 40px;
    font-size: 0.75rem;
    color: #ffffff;
    line-height: 1rem;
    @include breakpoint(768px, max) {
        padding: 30px 0;
        text-align: center;
    }

    img {
        @include breakpoint(768px, max) {
            margin-bottom: 30px;
        }
    }

    span {
        @include breakpoint(768px, max) {
            display: block;
        }
    }

    a {
        font-size: 0.75rem;
        color: #ffffff;
    }
    p {
        margin-bottom: 0;
    }
}