/*!
Theme Name: Carine Pedrotti
Theme URI: https://www.agencidominio.com.br/
Description: Site desenvolvido especificamente para uso da Agencia Dominio;
Author: Agencia Dominio
Author URI: https://www.agencidominio.com.br/
Version: 1.0
*/

$urlAssets: 'assets/';

// Imports
@import "mixins/mixins";
@import "commom/imports";
// @import "commom/keyframes";
@import "commom/variables";
@import "commom/fonts";
@import "commom/default";
@import "commom/padroes";
// @import "commom/loader";
// @import "commom/hidden";

//Components
@import "components/h1";
// @import "components/h2";
// @import "components/h3";
@import "components/buttons";
@import "components/texto";
@import "components/inputs";
// @import "components/select";
@import "components/fullTitle";
@import "components/paginacao";
@import "components/compartilhe";
// @import "components/modal";
@import "components/whatsapp";

//Layout Site
@import "header";
@import "vitrine";
@import "sobre";
@import "terapias";
@import "sexualidade";
@import "beneficios";
@import "blog";
@import "curso";
@import "contato";
@import "footer";

// @import "notFound";

