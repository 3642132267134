.btn {
    position: relative;
    font-weight: 600;
    text-transform: uppercase;
    padding: 1.25rem 2.1875rem;
    border-radius: 0;
    font-size: 1rem;
    line-height: 1.25rem;
    text-transform: uppercase;
    border: 0;
    &::before {
        position: absolute;
        content: '';
        width: 0;
        height: 2px;
        left: 0;
        bottom: 0;
        transition: all .3s ease;
    }
    &:hover {
        &::before {
            width: 100%;
        }
    }
    @include breakpoint(768px, max) {
        display: block;
    }
    &.btn-roxo {
        background-color: $roxo;
        color: $azul;
        &::before {
            background-color: $azul;
        }
        &:hover {
            background-color: $roxoHover;
        }
    }
    &.btn-azul {
        background-color: $azul;
        color: $roxo;
        &::before {
            background-color: $roxo;
        }
        &:hover {
            background-color: $azulHover;
        }
    }
    &.btn-verde {
        display: inline-flex;
        flex-wrap: wrap;
        padding: 10px 20px;
        align-items: center;
        background-color: green;
        color: #FFF;
        border-radius: 30px;
        font-size: 14px;
        @include breakpoint(tablet) {
            margin-bottom: 30px;
        }
        i {
            font-size: 26px;
            margin-right: 6px;
        }
        &::before {
            display: none;
        }
        &:hover {
            background-color: rgb(1, 91, 1);
        }
    }
}