.beneficios {
    position: relative;
    z-index: 2;
    display: block;
    width: 100%;
    height: auto;
    // margin-bottom: 80px;

    article {
        position: relative;
        z-index: 3;
        background-color: $roxo;
        padding: 80px 0;
        h1 {
            color: #FFFFFF;
            small {
                color: $azul;
                font-size: 1.125rem;
                line-height: 1.375rem;
                font-weight: 600;
            }
        }
    
        .texto {
            color: #FFFFFF;
        }
    
        .topicos {
            .topico {
                position: relative;
                display: flex;
                align-items: center;
                width: 102px;
                height: 102px;
                border-radius: 50%;
                margin-bottom: 40px;
                margin: auto;
                &::before {
                    position: absolute;
                    z-index: 1;
                    content: '';
                    width: 114px;
                    height: 114px;
                    border: #FFFFFF 3px solid;
                    border-radius: 50%;
                    border-width: 0 56px 0 0;
                    right: -6px;
                    top: -6px;
                    transition: all .3s ease;
                }
                &::after {
                    position: absolute;
                    z-index: 1;
                    content: '';
                    width: 114px;
                    height: 114px;
                    border: #FFFFFF 3px solid;
                    border-radius: 50%;
                    left: -6px;
                    top: -6px;
                }
                figure {
                    position: relative;
                    z-index: 3;
                    width: 102px;
                    height: 102px;
                    background-color: $azul;
                    border-radius: 50%;
                    margin: 0;
                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                &:hover {
                    &::before {
                        border-width: 0 112px 0 0;
                    }
                }
            }
            h2 {
                font-size: 600px;
                text-align: center;
                font-size: 0.875rem;
                line-height: 18px;
                margin: 20px 0 30px;
                color: #ffffff;
            }
        }
    }

    .containerWaveHeader {
        position: relative;
        .wave1 {
            position: absolute;
            display: block;
            width: 100%;
            margin-top: -10px;
            z-index: 1;
        }
        .wave2 {
            position: relative;
            z-index: 2;
        }
    }
    .containerWaveFooter {
    
        margin-top: -80px;
    }


}