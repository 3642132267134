header {
    position: fixed;
    padding: 8px 0;
    display: block;
    width: 100%;
    background-color: $roxo;
    top: 0;
    z-index: 9;
    border-top: $azul 8px solid;

    .logo {
        a {
            position: relative;
            z-index: 9999;
            display: block;
            width: 100%;
            padding: 16px 0;
            transition: all .3s ease;
        }
    }

    .barraInfos {
        text-align: right;
        transition: all .3s ease;
        margin-top: 12px;
        @include breakpoint(768px, max) {
            display: none;
        }
        .redes {
            display: inline-block;
            vertical-align: middle;
            padding: 0;
            nav {
                display: block;
                ul {
                    display: block;
                    text-align: right;
                    margin: 0;
                    li {
                        list-style-type: none;
                        display: inline-block;
                        padding: 0 5px;
                        a {
                            display: block;
                            color: #ffffff;
                            font-size: 30px;
                            text-align: center;
                            transition: all .3s ease;
                            &:hover {
                                transform: scale(1.1);
                            }
                        }
                    }
                }
            }
        }
    }

    .menu {
        display: block;
        padding: 22px 0 0;

        .botaoResponsivo {
            width: 50px;
            height: 40px;
            position: absolute;
            right: 5px;
            display: none;
            margin-top: 0;
            top: -5px;
            z-index: 9999;
            @include breakpoint(768px, max) {
                display: inline-block;
                top: 15px;
            }
            .linhasmr {
                float: right;
                margin-top: 8px;
                .b1 {
                    top: 11px;
                }
                .b2 {
                    top: 19px;
                }
                .b3 {
                    top: 27px;
                }
                >span {
                    background-color: #FFF;
                    height: 2px;
                    right: 0;
                    position: absolute;
                    top: 0;
                    transition: all 0.3s ease 0s;
                    width: 26px;
                    border-radius: 0px;
                }
            }
            &.active {
                .linhasmr {
                    >span {
                        background-color: #FFF;
                    }
                    .b1 {
                        transform: rotate(-45deg);
                        -webkit-transform: rotate(-45deg);
                        top: 19px;
                        right: 0;
                    }
                    .b2 {
                        width: 0px;
                    }
                    .b3 {
                        transform: rotate(45deg);
                        -webkit-transform: rotate(45deg);
                        top: 19px;
                        right: 0;
                    }
                }
            }
        }
        nav {
            transition: all .4s ease-in-out;
            &::before {
                position: absolute;
                content: '';
                width: 100%;
                height: 95px;
                z-index: 1;
                background-color: $roxo;
                top: 0;
                left: 0;
                right: 0;
                display: none;
                @include breakpoint(768px, max) {
                    display: block;
                }
            }
            @include breakpoint(768px, max) {
                width: 100vw;
                display: block;
                position: absolute;
                z-index: 999;
                background-color: $azul;
                right: -120vw;
                position: fixed;
                top: 0;
                height: auto;
                min-height: 100vh;
                overflow: auto;
                padding-top: 30px;
            }
            ul {
                margin: 0;
                text-align: right;
                @include breakpoint(768px, max) {
                    padding: 0 30px;
                    text-align: left;
                    margin-top: 100px;
                }
                li {
                    position: relative;
                    list-style-type: none;
                    display: inline-block;
                    @include breakpoint(768px, max) {
                        display: block;
                    }
                    a {
                        display: block;
                        color: #ffffff;
                        font-weight: 300;
                        font-size: 0.875rem;
                        padding: 0 24px;
                        text-transform: uppercase;
                        transition: all .3s ease;
                        @include breakpoint(768px, max) {
                            font-size: 1rem;
                            position: relative;
                            padding: 0;
                            padding-bottom: 8px;
                            color: $roxo;
                            border-bottom: $azulHover 1px solid;
                            margin-bottom: 15px;
                        }
                        &::before {
                            display: block;
                            position: absolute;
                            content: '';
                            width: 0;
                            height: 8px;
                            background-color: $azul;
                            left: 50%;
                            bottom: -24px;
                            transition: all .3s ease;
                            transform: translateX(-50%);
                            @include breakpoint(768px, max) {
                                display: none;
                            }
                        }
                        &:hover {
                            text-decoration: none;
                            color: $azul;
                            &::before {
                                width: 40px;
                            }
                        }
                        &.active {
                            color: $azul;
                            &::before {
                                width: 40px;
                            }
                        }
                    }
                    &:last-child {
                        a {
                            padding-right: 0;
                            &::before {
                                left: 65%;
                            }
                        }
                    }
                }
            }
            &.active {
                right: 0px;
            }
        }
        .barraInfos {
            display: none;
            @include breakpoint(768px, max) {
                display: block;
            }
            .telefone {
                display: block;
                text-align: center;
                padding: 30px 0;
                margin-right: 0;
            }
            .redes {
                display: block;
                @include breakpoint(768px, max) {
                    margin-top: 50px;
                }
                nav {
                    position: relative;
                    right: auto;
                    top: auto;
                    min-height: 0;
                    padding-top: 0;
                    &::before {
                        @include breakpoint(768px, max) {
                            display: none;
                        }
                    }
                    ul {
                        text-align: left;
                        li {
                            a {
                                padding-right: 10px;
                                border: 0;
                                color: $roxo;
                                font-size: 2rem;
                            }
                        }
                    }
                }
            }
        }
    }

    &.active {
        // background-color: $azul;
    }

    &.scroll {
        padding: 0;
        height: 80px;
        @include breakpoint(768px, max) {
            padding: 8px 0;
            height: auto;
        }
        &::before {
            width: 100%;
        }
        .logo {
            a {
                padding: 0;
                transform: scale(0.7) translate(-50px, 6px);
                @include breakpoint(768px, max) {
                    padding: 16px 0;
                    transform: scale(1) translate(0,0);
                }
            }
        }
        .barraInfos {
            transform: translateY(-200px);
            opacity: 0;
        }
        .menu {
            padding-top: 0;
            margin-top: -15px;
            .barraInfos {
                @include breakpoint(768px, max) {
                    transform: none;
                    opacity: 1;
                }
            }
        }
    }
    
}