/*
  SASS Variables for project
 */
$version: "v1.0.0";
$author: "Alexander Moraes";

// COLORS DEFAULT
$valid: #2ecc71;
$error: #FF0000;

$cinza: #7D7D7D;
$preto: #000;
// $azul: #004076;

$verde: #33ECC5;
$verdeFraco: #00636B;
$amarelo: #E0A62D;
$laranja: #F1935A;

$azulF: #12305D;

$azul: #A5D7E1;
$azulHover: #9FCDD6;
$roxo: #3A1C36;
$roxoHover: #5E2665;

//Tamanho Container
$containerWidth: 1170px;

//Font Padrão do Site
$fontDefault: 'Montserrat', sans-serif;;

$corBarraResponsivo: #FFFFFF;

// PATHS
$fontsSRC: "../fonts/";
$img: $urlAssets + "imgs/";


// BREAKPOINTS
$breakpoints: (max: (xs: 320px, mob: 768px, tablet: 1024px), min: (sm: 768px, md: 992px, lg: 1200px));