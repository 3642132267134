.compartilhe {
	margin-top: 50px;

	h3 {
		position: relative;
		display: block;
		font-weight: 400;
		font-size: .875rem;
		line-height: 1.375rem;
		color: #6F6F6F;
	}

	ul {
		display: inline-block;
		width: auto;

		li {
			list-style-type: none;
			display: inline-block;
			padding: 0 5px;
			a {
				display: block;
				color: $roxo;
				font-size: 2rem;
				text-align: center;
				transition: all .3s ease;
				&:hover {
					transform: scale(1.1);
				}
			}
		}

	}
}