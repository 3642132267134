.fullTitle {
    display: block;
    width: 100%;
    height: auto;
    background-color: #F0EBE8;
    padding: 150px 0 0;
    margin-bottom: 90px;
    @include breakpoint(768px, max) {
        padding-top: 160px;
        margin-bottom: 60px;
    }

    .container {
        h1 {
            display: block;
            text-align: center;
            width: 100%;
            font-size: 2rem;
            text-transform: uppercase;
            color: $roxo;
            margin-bottom: 0;
        }
    }
}